import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatSelGuidelinesService } from "src/app/services/mat-sel-guidelines/mat-sel-guidelines.service";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import { PRESSURE_UNITS_KPA_ARRAY } from "src/app/shared/consts/pressure-units";
import { TEMPERATURE_UNITS_ARRAY } from "src/app/shared/consts/temperature-units";
import { PressureUnits } from "src/app/shared/enums/pressure-units.enum";
import { Grade } from "../models/grade";
import Swal from "sweetalert2";
import { TemperatureUnits } from "src/app/shared/enums/temperature-units.enum";
import { UnitMeasures } from "src/app/shared/enums/unit-measures.enum";
import { combineLatest, merge } from "rxjs";
import { CONCENTRATION_UNITS_MEQL_ARRAY } from "src/app/shared/consts/concentration-units";
import { ConcentrationUnits } from "src/app/shared/enums/concentration-units.enum";
import { CalculateOpt2Request, RunModelOpt2Request } from "../models/opt2";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSelGuidelinesComponentsService } from "../services/mat-sel-guidelines-components.service";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";
import { pairwise, startWith } from "rxjs/operators";
import { NumericInputValidator } from "src/app/shared/validators/numeric-input.validator";
import { REGEXP_PATTERNS } from "src/app/shared/consts/regexp-patterns";
import { InterchaneableUnitValidator } from "src/app/shared/validators/interchangeable-unit.validator";

@Component({
  selector: "app-option2",
  templateUrl: "./option2.component.html",
  styleUrls: ["./option2.component.css"],
})
export class Option2Component implements OnInit {
  fg: FormGroup;
  fgPH: FormGroup;

  grades: Grade[] = [];

  imgModelDefault: any;
  imgModel: any;
  imgPH: any;
  imgModelDefaultString: any;
  imgModelString: any;
  imgPHString: any;

  oPH: number;

  readonly TEMPERATURE_UNITS_ARRAY = TEMPERATURE_UNITS_ARRAY;
  readonly PRESSURE_UNITS_KPA_ARRAY = PRESSURE_UNITS_KPA_ARRAY;
  readonly CONCENTRATION_UNITS_MEQL_ARRAY = CONCENTRATION_UNITS_MEQL_ARRAY;

  pph2sUnit: FormControl = new FormControl(PressureUnits.Kpa);
  ppCO2Unit: FormControl = new FormControl(PressureUnits.Kpa);
  ppH2SUnit: FormControl = new FormControl(PressureUnits.Kpa);
  ca2Unit: FormControl = new FormControl(
    ConcentrationUnits.MilliequivalentsLiter
  );
  hco3Unit: FormControl = new FormControl(
    ConcentrationUnits.MilliequivalentsLiter
  );
  temperatureUnit: FormControl = new FormControl(TemperatureUnits.Celsius);
  isLoading: boolean = false;

  hasPtCoord: FormControl = new FormControl(true);
  determinePH: FormControl = new FormControl(false);

  rangeOpt2: boolean = false;

  constructor(
    private fb: FormBuilder,
    public unitMeasureService: UnitMeasureService,
    private matSelGuidelinesService: MatSelGuidelinesService,
    private sanitizer: DomSanitizer,
    private matSelGuidelinesComponentsService: MatSelGuidelinesComponentsService,
    private preSimulationService: PreSimulationService
  ) {}

  ngOnInit(): void {
    this.fg = this.fb.group({
      phInSitu: [
        null,
        [
          Validators.required,
          Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
          NumericInputValidator.min(2.5),
          NumericInputValidator.max(6.5),
        ],
      ],
      pph2s: [
        null,
        [
          Validators.required,
          Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
          InterchaneableUnitValidator.delimited(
            0.01,
            1000,
            this.pph2sUnit,
            UnitMeasures.Pressure,
            this.unitMeasureService,
            { defaultUnit: PressureUnits.Kpa }
          ),
        ],
      ],
      pph2sDefault: [null, Validators.required],
    });

    this.fgPH = this.fb.group(
      {
        ppCO2: [
          null,
          [
            Validators.required,
            Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
          ],
        ],
        ppCO2Default: [null, Validators.required],
        oPpCO2: [null],
        ppH2S: [
          null,
          [
            Validators.required,
            Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
          ],
        ],
        ppH2SDefault: [null, Validators.required],
        oPpH2S: [null],
        ca2: [
          null,
          [
            Validators.required,
            Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
            NumericInputValidator.min(0),
          ],
        ],
        ca2Default: [null, Validators.required],
        oCa2: [null],
        hco3: [
          null,
          [
            Validators.required,
            Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
            NumericInputValidator.min(0),
          ],
        ],
        hco3Default: [null, Validators.required],
        oHco3: [null],
        temperature: [
          null,
          [
            Validators.required,
            Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
            InterchaneableUnitValidator.delimited(
              20,
              150,
              this.temperatureUnit,
              UnitMeasures.Temperature,
              this.unitMeasureService
            ),
          ],
        ],
        temperatureDefault: [null, Validators.required],
        oTemperature: [null],
        oSumPP: [null],
      },
      { validators: this.sumValidator() }
    );

    combineLatest([
      this.formPH.ppCO2.valueChanges,
      this.formPH.ppH2S.valueChanges,
    ]).subscribe(([ppCO2Value, ppH2SValue]) => {
      if (ppCO2Value && ppH2SValue)
        this.formPH.oSumPP.setValue(
          (
            Number(
              this.ppCO2Unit.value === PressureUnits.Kpa
                ? ppCO2Value
                : this.unitMeasureService.convertUnit(
                    ppCO2Value,
                    UnitMeasures.Pressure,
                    this.ppCO2Unit.value,
                    true,
                    4,
                    { defaultUnit: PressureUnits.Kpa }
                  )
            ) +
            Number(
              this.ppH2SUnit.value === PressureUnits.Kpa
                ? ppH2SValue
                : this.unitMeasureService.convertUnit(
                    ppH2SValue,
                    UnitMeasures.Pressure,
                    this.ppH2SUnit.value,
                    true,
                    4,
                    { defaultUnit: PressureUnits.Kpa }
                  )
            )
          ).toFixed(4),
          { emitEvent: false }
        );
      else this.formPH.oSumPP.setValue(null, { emitEvent: false });
    });

    this.formPH.ppCO2.valueChanges.subscribe((value) => {
      this.formPH.oPpCO2.setValue(
        this.ppCO2Unit.value === PressureUnits.Kpa
          ? value
          : this.unitMeasureService.convertUnit(
              value,
              UnitMeasures.Pressure,
              this.ppCO2Unit.value,
              true,
              4,
              { defaultUnit: PressureUnits.Kpa }
            ),
        { emitEvent: false }
      );
    });
    this.formPH.ppH2S.valueChanges.subscribe((value) => {
      this.formPH.oPpH2S.setValue(
        this.ppH2SUnit.value === PressureUnits.Kpa
          ? value
          : this.unitMeasureService.convertUnit(
              value,
              UnitMeasures.Pressure,
              this.ppH2SUnit.value,
              true,
              4,
              { defaultUnit: PressureUnits.Kpa }
            ),
        { emitEvent: false }
      );
    });
    this.formPH.ca2.valueChanges.subscribe((value) => {
      this.formPH.oCa2.setValue(
        this.ca2Unit.value === ConcentrationUnits.MilliequivalentsLiter
          ? value
          : this.unitMeasureService.convertUnit(
              value,
              UnitMeasures.Concentration,
              this.ca2Unit.value,
              true,
              4,
              {
                defaultUnit: ConcentrationUnits.MilliequivalentsLiter,
                molecularWeight: 40,
                valence: 2,
              }
            ),
        { emitEvent: false }
      );
    });
    this.formPH.hco3.valueChanges.subscribe((value) => {
      this.formPH.oHco3.setValue(
        this.hco3Unit.value === ConcentrationUnits.MilliequivalentsLiter
          ? value
          : this.unitMeasureService.convertUnit(
              value,
              UnitMeasures.Concentration,
              this.hco3Unit.value,
              true,
              4,
              {
                defaultUnit: ConcentrationUnits.MilliequivalentsLiter,
                molecularWeight: 61,
                valence: 1,
              }
            ),
        { emitEvent: false }
      );
    });
    this.formPH.temperature.valueChanges.subscribe((value) => {
      this.formPH.oTemperature.setValue(
        this.temperatureUnit.value === TemperatureUnits.Celsius
          ? value
          : this.unitMeasureService.convertUnit(
              value,
              UnitMeasures.Temperature,
              this.temperatureUnit.value,
              true,
              4
            ),
        { emitEvent: false }
      );
    });

    merge(
      this.fg.get("phInSitu").valueChanges.pipe(pairwise()),
      this.fg.get("pph2sDefault").valueChanges.pipe(pairwise())
    ).subscribe(([prevVal, currVal]) => {
      if (prevVal !== currVal) {
        this.imgModel = this.imgModelDefault;
        this.imgModelString = this.imgModelDefaultString;
        this.imgPH = null;
        this.imgPHString = null;
        this.oPH = null;

        this.matSelGuidelinesComponentsService.setIsPrinteable(false);
        this.matSelGuidelinesComponentsService.fireFormChanges();
      }
    });

    this.fg.valueChanges.subscribe(() => {
      this.matSelGuidelinesComponentsService.updateFgOpt2(this.fg.value);
      this.matSelGuidelinesComponentsService.updateFormOpt2(this.fg);

      if (!this.isLoading && this.fg.invalid && this.fgPH.invalid)
        this.matSelGuidelinesComponentsService.updateModelOpt2(null);
    });

    merge(
      this.fgPH.get("ppCO2Default").valueChanges.pipe(pairwise()),
      this.fgPH.get("ppH2SDefault").valueChanges.pipe(pairwise()),
      this.fgPH.get("ca2Default").valueChanges.pipe(pairwise()),
      this.fgPH.get("hco3Default").valueChanges.pipe(pairwise()),
      this.fgPH.get("temperatureDefault").valueChanges.pipe(pairwise())
    ).subscribe(([prevVal, currVal]) => {
      if (prevVal !== currVal) {
        this.imgModel = this.imgModelDefault;
        this.imgModelString = this.imgModelDefaultString;
        this.imgPH = null;
        this.imgPHString = null;
        this.oPH = null;

        this.matSelGuidelinesComponentsService.setIsPrinteable(false);
        this.matSelGuidelinesComponentsService.fireFormChanges();
      }
    });

    this.fgPH.valueChanges.subscribe(() => {
      this.matSelGuidelinesComponentsService.updateFgPhOpt2(this.fgPH.value);
      this.matSelGuidelinesComponentsService.updateFormPhOpt2(this.fgPH);

      if (!this.isLoading && this.fg.invalid && this.fgPH.invalid)
        this.matSelGuidelinesComponentsService.updateModelOpt2(null);
    });

    combineLatest([
      this.pph2sUnit.valueChanges.pipe(startWith(this.pph2sUnit.value)),
      this.ppCO2Unit.valueChanges.pipe(startWith(this.ppCO2Unit.value)),
      this.ppH2SUnit.valueChanges.pipe(startWith(this.ppH2SUnit.value)),
      this.ca2Unit.valueChanges.pipe(startWith(this.ca2Unit.value)),
      this.hco3Unit.valueChanges.pipe(startWith(this.hco3Unit.value)),
      this.temperatureUnit.valueChanges.pipe(
        startWith(this.temperatureUnit.value)
      ),
    ]).subscribe(
      ([
        pph2sValue,
        ppCO2Value,
        ppH2SValue,
        ca2Value,
        hco3Value,
        temperatureValue,
      ]) => {
        const units = {
          pph2s: pph2sValue,
          ppCO2: ppCO2Value,
          ppH2S: ppH2SValue,
          ca2: ca2Value,
          hco3: hco3Value,
          temperature: temperatureValue,
        };

        this.matSelGuidelinesComponentsService.updateOpt2Units(units);
      }
    );

    this.runModel();

    this.fgPH.disable();

    this.matSelGuidelinesComponentsService.loadOpt2Obs.subscribe((load) => {
      this.isLoading = true;

      if (load) {
        this.fg?.patchValue({
          phInSitu: load.model_ph,
          pph2s: this.unitMeasureService.convertUnit(
            load.model_partial_pressure_H2S,
            UnitMeasures.Pressure,
            this.pph2sUnit.value,
            false,
            4,
            { defaultUnit: PressureUnits.Kpa }
          ),
        });

        this.hasPtCoord.setValue(load.hasPtCoord);

        this.fgPH?.patchValue({
          ppCO2: this.unitMeasureService.convertUnit(
            load.partial_pressure_CO2,
            UnitMeasures.Pressure,
            this.ppCO2Unit.value,
            false,
            4,
            { defaultUnit: PressureUnits.Kpa }
          ),
          ppH2S: this.unitMeasureService.convertUnit(
            load.partial_pressure_H2S,
            UnitMeasures.Pressure,
            this.ppH2SUnit.value,
            false,
            4,
            { defaultUnit: PressureUnits.Kpa }
          ),
          ca2: this.unitMeasureService.convertUnit(
            load.ca,
            UnitMeasures.Concentration,
            this.ca2Unit.value,
            false,
            4,
            {
              defaultUnit: ConcentrationUnits.MilliequivalentsLiter,
              molecularWeight: 40,
              valence: 2,
            }
          ),
          hco3: this.unitMeasureService.convertUnit(
            load.hcO3,
            UnitMeasures.Concentration,
            this.hco3Unit.value,
            false,
            4,
            {
              defaultUnit: ConcentrationUnits.MilliequivalentsLiter,
              molecularWeight: 61,
              valence: 1,
            }
          ),
          temperature: this.unitMeasureService.convertUnit(
            load.temperature,
            UnitMeasures.Temperature,
            this.temperatureUnit.value,
            false,
            4,
            { defaultUnit: TemperatureUnits.Celsius }
          ),
        });

        if (!this.areAllValuesNullOrUndefined(this.fgPH.value)) {
          this.determinePH.setValue(true);
          this.updateAllControls(this.fgPH);
        } else if (!this.areAllValuesNullOrUndefined(this.fg.value)) {
          this.determinePH.setValue(false);
          this.updateAllControls(this.fg);
        }

        if (this.fgPH.valid) this.calculate();
        else if (this.fg.valid) this.runModel();
      } else this.onNew();
    });

    this.matSelGuidelinesComponentsService.resetValuesObs.subscribe(() => {
      this.onNew();
    });

    this.hasPtCoord.valueChanges.subscribe((value) => {
      if (this.fgPH.valid) this.calculate();
      else if (this.fg.valid) this.runModel();

      this.matSelGuidelinesComponentsService.setHasPtCoord(value);
    });

    this.determinePH.valueChanges.subscribe((value) => {
      if (value) {
        this.fg.reset();
        this.fg.disable();
        this.fgPH.enable();
      } else {
        this.fgPH.disable();
        this.fg.enable();
        this.fgPH.reset();
      }
    });
  }

  sumValidator(): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: any } | null => {
      const ppCO2 = formGroup.get("ppCO2Default")?.value;
      const ppH2S = formGroup.get("ppH2SDefault")?.value;
      const sum = ppCO2 + ppH2S;

      if (
        sum >= 1 &&
        sum <= 10000 &&
        (formGroup.get("ppCO2").dirty || formGroup.get("ppH2S").dirty)
      )
        return null;
      else if (formGroup.get("ppCO2").dirty && formGroup.get("ppH2S").dirty)
        return { sumInvalid: true };
      else return null;
    };
  }

  onNew() {
    this.fg.reset();
    this.fgPH.reset();
  }

  onNewInput() {
    this.fg.reset();
  }

  onNewCalculatePH() {
    this.fgPH.reset();
  }

  onSubmit() {
    if (this.fg.invalid)
      Swal.fire({
        title: "Action required",
        text: "Please complete all inputs to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });

    this.runModel();
  }

  runModel() {
    let request: RunModelOpt2Request = {
      ppH2s: !this.form.pph2sDefault.value ? 0 : this.form.pph2sDefault.value,
      pH: !this.form.phInSitu.value ? 0 : this.form.phInSitu.value,
      hasPoint:
        !this.form.pph2sDefault.value && !this.form.phInSitu.value
          ? true
          : false,
      customerId: this.preSimulationService.getCurrentCustomerId(),
      countryId: this.preSimulationService.getCurrentCountryId(),
      hasPtCoord: this.hasPtCoord.value,
    };

    this.matSelGuidelinesService.runModelOpt2(request).subscribe((response) => {
      this.isLoading = false;
      if (!response.hasError) {
        this.imgModel = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/png;base64, ${response.imageBlob}`
        );
        this.imgModelString = response.imageBlob;

        if (request.hasPoint) {
          this.imgModelDefault = this.sanitizer.bypassSecurityTrustResourceUrl(
            `data:image/png;base64, ${response.imageBlob}`
          );

          this.imgModelDefaultString = response.imageBlob;
        }
        // Select the element by its ID
        const element = document.getElementById("imgModel");

        // Check if the element exists to avoid errors
        if (element) {
          // Scroll the element into view
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        this.matSelGuidelinesComponentsService.updateFgOpt2(this.fg.value);
        this.matSelGuidelinesComponentsService.updateModelOpt2({
          imgModel: this.imgModelString,
        });
      } else {
        this.imgModel = this.imgModelDefault;
        this.imgModelString = this.imgModelDefaultString;
        Swal.fire({
          title: response.errorMessage,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });

        return;
      }
    });
  }

  areAllValuesNullOrUndefined(obj: any): boolean {
    return Object.values(obj).every(
      (value) =>
        value === null ||
        value === undefined ||
        (typeof value === "object" && Object.keys(value).length === 0)
    );
  }

  calculate() {
    if (this.fgPH.invalid)
      Swal.fire({
        title: "Action required",
        text: "Please complete all inputs to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });

    let request: CalculateOpt2Request = {
      ppCO2: this.formPH.ppCO2Default.value,
      ppH2S: this.formPH.ppH2SDefault.value,
      ca: this.formPH.ca2Default.value,
      hco3: this.formPH.hco3Default.value,
      temperature: this.formPH.temperatureDefault.value,
      customerId: this.preSimulationService.getCurrentCustomerId(),
      countryId: this.preSimulationService.getCurrentCountryId(),
      hasPtCoord: this.hasPtCoord.value,
    };

    this.matSelGuidelinesService.calculate(request).subscribe((response) => {
      this.isLoading = false;

      if (!response.imgRegion.hasError) {
        this.imgModel = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/png;base64, ${response.imgRegion.imageBlob}`
        );
        this.imgModelString = response.imgRegion.imageBlob;
      } else {
        Swal.fire({
          title: response.imgRegion.errorMessage,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });

        return;
      }

      if (!response.imgCalcPh.hasError) {
        this.imgPH = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/png;base64, ${response.imgCalcPh.imageBlob}`
        );
        this.imgPHString = response.imgCalcPh.imageBlob;

        // Select the element by its ID
        const element = document.getElementById("imgModel");

        // Check if the element exists to avoid errors
        if (element) {
          // Scroll the element into view
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else {
        Swal.fire({
          title: response.imgCalcPh.errorMessage,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });

        return;
      }

      this.oPH = response.ph;
      this.form.pph2s.setValue(
        this.unitMeasureService.convertUnit(
          request.ppH2S,
          UnitMeasures.Pressure,
          this.pph2sUnit.value,
          false,
          4,
          { defaultUnit: PressureUnits.Kpa }
        ),
        { emitEvent: false }
      );
      this.form.pph2sDefault.setValue(request.ppH2S, { emitEvent: false });
      this.form.phInSitu.setValue(response.ph, { emitEvent: false });

      this.matSelGuidelinesComponentsService.updateFgOpt2(this.fg.value);
      this.matSelGuidelinesComponentsService.updateFgPhOpt2(this.fgPH.value);
      this.matSelGuidelinesComponentsService.updateModelOpt2({
        oPH: this.oPH,
        imgPH: this.imgPHString,
        imgModel: this.imgModelString,
      });
    });
  }

  rangePopUp() {
    this.rangeOpt2 = true;
  }

  closeModal() {
    this.rangeOpt2 = false;
  }

  updateAllControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.updateAllControls(control);
      } else {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }

  get form() {
    return this.fg.controls;
  }

  get formPH() {
    return this.fgPH.controls;
  }
}
